/**
 *  商品选购
 */
import request from '@/utils/request'

/**
 * 获取商品分类信息
 * @param {*} parent_id 
 * @returns 
 */
export const getCategorys = (parent_id = 0,params) => request({
  url: `/seller/goods/dropshipping/${parent_id}/children`,
  method: 'get',
  params
})

/**
 * 获取一级分类下的子分类
 * @param {*} first_id 
 * @returns 
 */
export const getFirstchildren = (first_id = 0,params) => request({
  url: `/seller/goods/dropshipping/${first_id}/firstchildren`,
  method: 'get',
  params
})

/**
 * 获取图片
 * @param {*} params 
 * @returns 
 */
export const getGoodsGallery = params => request({
  url: '/seller/shopGoods/getGoodsGallery',
  method: 'get',
  params
})

// 获取购物车详情
export const getCarts = (params = {}) => request({
  url: '/seller/trade/carts',
  method: 'get',
  params
})
// 添加购物车
export const addCarts = params => request({
  url: '/seller/trade/carts',
  method: 'post',
  params
})
// 修改购物车
export const putCarts = (params = {}) => request({
  url: '/seller/trade/carts',
  method: 'put',
  params,
  loading: false
})
// 删除购物车
export const deleteCarts = (params = {}) => request({
  url: '/seller/trade/carts',
  method: 'delete',
  params
})

/**检查购物车商品的状态 */
export const checkCartGoodsStock = () => request({
  url: '/seller/trade/carts/checkCartGoodsStock',
  method: 'get'
})

// 勾选取消购物车商品
export const checkedCarts = params => request({
  url: '/seller/trade/carts/checkedCarts',
  method: 'put',
  params
})

/**
 * 一件代发导入收货地址
 * @param {Array} data 
 * @returns 
 */
export const addressesImpart = data => request({
  url: '/seller/members/addresses/impart',
  method: 'post',
  headers: {
    'Content-Type': 'application/json'
  },
  data,
  loading: false
})

/**
 * 一件代发查询地址列表
 * @param {object} params 
 * @returns 
 */
export const getAddr = params => request({
  url: '/seller/members/addresses/getAddr',
  method: 'get',
  params
})

/**
 * 一件代发购物车批量下单
 * @param {*} data 
 * @returns 
 */
export const createCartTrade = data => request({
  url: '/seller/trade/createCartTrade',
  method: 'post',
  headers: {
    'Content-Type': 'application/json'
  },
  data
  }
)

/**
 * 获取订单列表
 * @param {object} params 
 * @returns 
 */
export const getOrderList = batch_sn => request({
  url: `/seller/trade/getBatch/${batch_sn}`,
  method: 'get',
})

/**
 * 一件代批量批量支付
 * @param {*} data 
 * @returns 
 */
export const payTrade = params => request({
  url: '/seller/trade/payTrade',
  method: 'post',
  params,
  loading: false
})
  
/**
 * 获取站点账户余额
 * @param {*} _ 
 * @returns 
 */
export const getShopBalance = _ => request({
  url:'seller/shops/get-shop-balance'
})

/**
 * 修改备注
 * @param {{ids:string[],remark:string}} params
 */
export const updateRemark = params => request({
  url: '/seller/members/addresses/edit',
  method: 'put',
  params
})